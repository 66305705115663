'use strict';

const cache = {
    $body: $('body'),
};

function notifyMinicartUpdate(quantityTotal) {
    cache.$body.trigger('minicart:updateQuantity', {
        quantity: quantityTotal,
    });
};

module.exports = {
    notifyMinicartUpdate,
};
