'use strict';

const cache = {
    $body: $('body'),
    $containerProductMiniCart: $('.js-containerProductMiniCart'),
    $miniCartQty: $('.js-minicart-quantity'),
    $productCard: $('.js-productCard'),
    $promotions: $('.js-promotions')
};

function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.choice-of-bonus-product');
    const footer = $html.find('.js-confirm-view').children();

    return { body, footer };
}

module.exports = {
    parseHtml: parseHtml,
    chooseBonusProducts: function (data) {

        let bonusUrl;
        if (data.bonusChoiceRuleBased) {
            bonusUrl = data.showProductsUrlRuleBased;
        } else {
            bonusUrl = data.showProductsUrlListBased;
        }

        const htmlString = '<!-- Modal -->'
            + '<div class="modal fade scroll" id="chooseBonusProductModal" tabindex="-1" role="dialog">'
            + '<span class="enter-message sr-only" ></span>'
            + '<div class="modal-dialog choose-bonus-product-dialog modal-large max-w-30" '
            + 'data-total-qty="' + data.maxBonusItems + '"'
            + 'data-UUID="' + data.uuid + '"'
            + 'data-pliUUID="' + data.pliUUID + '"'
            + 'data-addToCartUrl="' + data.addToCartUrl + '"'
            + 'data-pagestart="0"'
            + 'data-pagesize="' + data.pageSize + '"'
            + 'data-moreURL="' + data.showProductsUrlRuleBased + '"'
            + 'data-bonusChoiceRuleBased="' + data.bonusChoiceRuleBased + '">'
            + '<!-- Modal content-->'
            + '<div class="modal-content">'
            + '<div class="modal-header">'
            + '    <button type="button" class="close pull-right" data-dismiss="modal">'
            + '        <span aria-hidden="true">&times;</span>'
            + '    </button>'
            + '    <p class="bofrost-paragraph cl-blue text-center">' + data.labels.selectprods + '</p>'
            + '</div>'
            + '<div class="modal-body px-4"><div class="row js-bonus-product-list"></div><div class="row js-confirm-bonus d-none"></div></div>'
            + '</div>'
            + '</div>'
            + '</div>';


        if (!$('#chooseBonusProductModal').length) {
            cache.$body.append(htmlString);
        }

        const $modalContent = $('.modal-content');
        $modalContent.spinner().start();

        const $choosebonusProductModal = $('#chooseBonusProductModal');

        $choosebonusProductModal.on('hidden.bs.modal', function() {
            $choosebonusProductModal.remove();
        });

        $.ajax({
            url: bonusUrl,
            method: 'GET',
            dataType: 'json',
            success(response) {
                if (response.error) {
                    $modalContent.spinner().stop();
                    return;
                }

                const parsedHtml = parseHtml(response.renderedTemplate);
                const title = response.title;
                const $bonusProductList = $choosebonusProductModal.find('.js-bonus-product-list');

                $bonusProductList.empty();
                $choosebonusProductModal.find('.enter-message').text(response.enterDialogMessage);

                if (!$('.js-description-promo').length) {
                    $choosebonusProductModal.find('.modal-header').append("<h2 class='text-center bofrost-title-medium js-description-promo mb-4'>" + title + "</h2>");
                }

                $choosebonusProductModal.find('.js-confirm-bonus').html(parsedHtml.footer);
                $bonusProductList.html(parsedHtml.body);
                // $choosebonusProductModal.find('.modal-footer').html(parsedHtml.footer);
                $choosebonusProductModal.modal({ backdrop: 'static' });
                $modalContent.spinner().stop();
            },
            error() {
                $modalContent.spinner().stop();
            },
        });

        $choosebonusProductModal.on('hidden.bs.modal', function (e) {
            $choosebonusProductModal.find('.js-description-promo').remove();
        });
    },
    addBonusProductsToCart: function () {
        cache.$body.on('click', '.js-tile-add-to-cart-bonus', function () {
            // var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            const $this = $(this);
            const $productList = $('.js-bonus-product-list');

            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var queryString = url.indexOf('?') > -1 ? '&pids=' : '?pids=';
      
            var pidsObject = {
                bonusProducts: []
            };

            var option = {};
            pidsObject.bonusProducts.push({
                pid: $this.data('pid'),
                qty: $this.data('qty'),
                options: [option]
            });

            pidsObject.totalQty = parseInt($this.data('qty'), 10);
            queryString += JSON.stringify(pidsObject);
            queryString += `&uuid=${$('.choose-bonus-product-dialog').data('uuid')}`;
            const pliUUID = $('.choose-bonus-product-dialog').data('pliuuid');
            queryString += `&pliuuid=${pliUUID || 'bonus'}`;

            $productList.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $productList.spinner().stop();
                    if (data.error) {
                        $('#chooseBonusProductModal').modal('hide');

                        // $('#chooseBonusProductModal').modal('hide');
                        // if ($('.add-to-cart-messages').length === 0) {
                        //     $('body').append('<div class="add-to-cart-messages"></div>');
                        // }
                        // $('.add-to-cart-messages').append(
                        //     '<div class="alert alert-danger add-to-basket-alert text-center"'
                        //     + ' role="alert">'
                        //     + data.errorMessage + '</div>'
                        // );
                        // setTimeout(function () {
                        //     $('.add-to-basket-alert').remove();
                        // }, 3000);
                    } else {
                        // debugger
                        if (cache.$containerProductMiniCart.length > 0) {
                            cache.$containerProductMiniCart.empty().append(data.templates.miniCartHTML);
                            cache.$miniCartQty.text(data.totalQty);
                        }

                        if (cache.$productCard.length > 0) {
                            cache.$productCard.empty().append(data.templates.cartHTML);
                        }

                        if (cache.$promotions.length > 0) {
                            cache.$promotions.empty().append(data.templates.promotionsHTML);
                        }
                        
                        const $confirmBonus = $('.js-confirm-bonus');
                        $confirmBonus.removeClass('d-none');
                        $productList.addClass('d-none');
                        // add Notify
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }).on('click', '.js-change-bonusProduct', function () {
            const $confirmBonus = $('.js-confirm-bonus');
            const $productList = $('.js-bonus-product-list');

            $confirmBonus.addClass('d-none');
            $productList.removeClass('d-none');
        });
    },
    showMoreBonusProducts: function () {
        cache.$body.on('click', '.js-show-more-bonus-products', function (e) {
            var url = $(this).data('url');

            const $modalContent = $('.modal-content');
            $modalContent.spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (response) {
                    var parsedHtml = parseHtml(response.renderedTemplate);
                    $('.js-bonus-product-list').append(parsedHtml.body);
                    $('.js-show-more:first').remove();
                    $modalContent.spinner().stop();
                },
                error: function (e) {
                    console.error(e);
                    $modalContent.spinner().stop();
                }
            });
        });
    }
}
