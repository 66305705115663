const cookieMgr = require('../cookies');
const bonusProducts = require('./bonusProducts');
const mobileAppHelper = require('../components/mobileAppHelpers');

const cache = {
    $body: $('body'),
    $minicart: $('.js-minicart'),
    $cartInfo: $('.js-cart-info'),
    $productCard: $('.js-productCard'),
    $cartPromotions: $('.js-promotions'),
    $cartSummary: $('.cart-summary'),
    $cartBody: $('.cart-body')
}

/**
 * Updates all the components of the Cart after the Add Product event
 *
 * ! In case of adding a new template, make sure:
 * - to cache the selector
 * - to rename the selector with $ + {templateName},
 *   where templateName must be the same name given in the html object !
 *
 * @param {Object} html - CartBody Templates
 */
function updateCartBody(html) {
    Object.keys(html).forEach((template) => {
        if(cache['$' + template]) cache['$' + template].html(html[template]);
    });
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {number} quantity - quantity
 * @param {string} selector - DOM element to look up in the tile's qty element
 * @return {undefined}
 */
function updateTile(quantity, selector) {
    const $selector = $(selector)
    $selector.empty().html(quantity);

    const $addToCart = $selector.closest('.add-to-cart');

    if (quantity && quantity > 0) {
        $addToCart.find('.tile-add-to-cart').removeClass('show');
        $addToCart.find('.update-quantity').addClass('show');
    } else {
        $addToCart.find('.tile-add-to-cart').addClass('show');
        $addToCart.find('.update-quantity').removeClass('show');
    }
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function updateQuantities() {
    const items = $('.cart-json').data('cart');
    const $addToCart = $('.add-to-cart');

    $addToCart.find('.tile-add-to-cart').addClass('show');
    $addToCart.find('.update-quantity').removeClass('show');

    if (items && items.itemsquantities && items.itemsquantities.length > 0) {
        items.itemsquantities.forEach((item) => {
            updateTile(item.qty, `.itemquantity-${item.id} .quantity`);
        });
    }
}

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    return $($el).closest('.add-to-cart').data('pid');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl($el) {
    return $($el).closest('.add-to-cart').data('endpoint-add-to-cart');
}

/**
 * Retrieves url to use when adding a product to the cart
 * @param {jquery} $el - DOM container for a given change qty button
 * @return {string} - The provided URL to use when changing quantity
 */
function getUpdateQuantityUrl($el) {
    return $($el).closest('.add-to-cart').data('endpoint-update-quantity');
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 * @param {jquery} el - DOM container for a given add to cart button
 */
function handlePostCartAdd(response, el) {
    if (cache.$cartBody.length) updateCartBody(response.templates.bodyCart);
    cache.$minicart.trigger('count:update', response);
    mobileAppHelper.notifyMinicartUpdate(response.quantityTotal);

    if (response && response.cart && response.cart.items) {
        const pid = el.closest('.add-to-cart').data('pid');
        const products = response.cart.items.filter((item) => item.id === pid);
        if (products && products.length > 0) {
            el.closest('.add-to-cart').find('.update-quantity').addClass('show');
            el.closest('.add-to-cart').find('.update-quantity .quantity').html('1');
            // el.removeClass('show');
        }
    }

    const messageType = response.error ? 'alert-danger' : 'alert-success';
    var message = response.message;
    // show add to cart toast
    if (response.newBonusDiscountLineItem &&
        Object.keys(response.newBonusDiscountLineItem).length !== 0 &&
        !cache.$cartBody.length) {
        bonusProducts.chooseBonusProducts(response.newBonusDiscountLineItem);
    } else if (messageType === 'alert-danger') {

        $.toast().start({
            type: 'error',
            message: message,
            orientation: 'bottom-right'
        });

    } else {
        cookieMgr.showModalPromotion(response.showPromotionModal);

        for (let product of response.products) {
            $.toast().start({
                type: 'product',
                params: {
                    image: product.images.medium[0].url,
                    productName: product.productName,
                    code: product.correctCode
                }
            });
        }
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the quantity change button
 * @param {jquery} el - DOM container for a given quantity change button
 */
function updateQuantity(response, el) {
    if (response && response.items && response.items) {
        let product;
        const pid = el.closest('.add-to-cart').data('pid');
        const products = response.items.filter((item) => item.id === pid);
        if (products && products.length > 0) {
            product = products.pop();
            el.closest('.add-to-cart').find('.update-quantity .quantity').html(product.quantity);
        }
    }
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url,
            method: 'GET',
            success() {
                // reporting urls hit on the server
            },
            error() {
                // no reporting urls hit on the server
            },
        });
    }
}

/**
 *  Cart-ReservationProduct Ajax
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function productReservation() {
    cache.$body.on('click', '#new-productReservation-btn', function (e) {
        e.preventDefault();
        const urlContent = $('#reservationUrl').data('url');
        let form = $('.productReservationForm').serialize()
        $.ajax({
            url: urlContent,
            type: 'post',
            data: form,
            success(res) {
                if (res.success) {
                    $('#productReservation').modal('toggle');
                    $('#errorReservation').hide();
                } else {
                    $('#errorReservation').text(res.error);
                    $('#errorReservation').show();
                }

            },
            error(res) {
                console.log("error");
            },
        });
    })
}

module.exports = {
    addToCart() {
        cache.$body.on('click', 'button.tile-add-to-cart', function () {
            const el = $(this);
            cache.$body.trigger('product:beforeAddToCart', this);

            const pid = getPidValue(el);

            let $productContainer = el.closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = el.closest('.quick-view-dialog').find('.product-detail');
            }

            const addToCartUrl = getAddToCartUrl(el);

            let quantityValue = 1;

            const $quantitySelector = $('input.js-quantity-button[data-pid="' + pid + '"]');

            if ($quantitySelector.length) {
                quantityValue = $('input.js-quantity-button[data-pid="' + pid + '"]').val();
            }

            const form = {
                pid,
                quantity: quantityValue
            };

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success(data) {
                        handlePostCartAdd(data, el);
                        cache.$body.trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                        if (Object.prototype.hasOwnProperty.call(data, 'addToCartFailed')) {
                            const buttonAddToCart = $("#addToCartBtn-" + pid);
                            buttonAddToCart.prop("disabled", true);
                            //TODO: to enable product reservation
                            // $('#productReservation').modal('toggle');
                            // $('#reservationProductID').val(pid);
                            // $('#reservationQuantity').val(data.quantity);
                            // $('#nameProduct').text(pid);
                            // $('#quantityProduct').text(data.quantity);
                        };
                        miniCartReportingUrl(data.reportingURL);
                    },
                    error() {
                        $.spinner().stop();
                    },
                });
            }
        });
    },
    updateQuantityCart() {
        $(document).on('click', 'button.tile-update-quantity', function () {
            const el = $(this);

            cache.$body.trigger('product:beforeAddToCart', this);

            const pid = getPidValue($(this));
            const updateQuantityUrl = getUpdateQuantityUrl($(this));
            const decrease = $(this).data('decrease');

            let $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }

            const form = {
                pid,
                decrease,
                quantity: 1,
            };

            $(this).trigger('updateAddToCartFormData', form);
            if (updateQuantityUrl) {
                $.ajax({
                    url: updateQuantityUrl,
                    method: 'GET',
                    data: form,
                    success(data) {
                        cache.$minicart.trigger('count:update', data);
                        cache.$body.trigger('product:afterAddToCart', data);
                        if (data && data.basket && data.basket.items) {
                            const id = el.closest('.add-to-cart').data('pid');
                            const products = data.basket.items.filter((item) => item.id === id);
                            if (products.length === 0) {
                                // el.closest('.add-to-cart').find('.update-quantity').removeClass('show');
                                el.closest('.add-to-cart').find('.tile-add-to-cart').addClass('show');
                            }
                        } else if (data && data.items) {
                            updateQuantity(data, el);
                        }
                        $.spinner().stop();
                        miniCartReportingUrl(data.reportingURL);
                    },
                    error() {
                        $.spinner().stop();
                    },
                });
            }
        });
    },
    updateMinicart() {
        cache.$body.on('cart:update', () => {
            updateQuantities();
        });
    },
    removeToast: function () {
        cache.$body.on('click', '.toast', function (e) {
            $('.add-to-basket-alert').remove();
        });
    },
    tileLoad: updateQuantities,
    productReservation: productReservation
};
