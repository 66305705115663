function getCookie (cname) {
    const name = cname + '=';
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach(val => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
    })
    return res;
}
function setCookie(name, value, days) {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

function deleteCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function showModalPromotion (data){
    var show = false;
    if(data !== null){
        var cookiePromotion = getCookie('promotion');
        if(cookiePromotion){
            var cookiePromotionJson = JSON.parse(cookiePromotion);
            if(!cookiePromotionJson.hasOwnProperty(data.id)){
                cookiePromotionJson[data.id] = data.id;
                setCookie('promotion', JSON.stringify(cookiePromotionJson));
                show = true;
            }else{
                show = false;
            }
        }else{
            var obj = {};
            obj[data.id] = data.id;
            setCookie('promotion', JSON.stringify(obj));
            show = true;
        }
        if(show){
            $('.description-promotion').text(data.shortDescription);
            $('.promotionImg').attr("src", data.images['medium'][0].url);
            var linkPromo = data.promotionUrl;
            $('.back-to-shop').attr("href", linkPromo);
            $('#activePromotionModal').modal('show');
        }
    }
}
module.exports = {
    getCookie : getCookie,
    setCookie : setCookie,
    deleteCookie : deleteCookie,
    showModalPromotion : showModalPromotion
}
